export const SkrillIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={24}
    viewBox='0 0 64 24'
    fill='none'
    {...props}
  >
    <path
      fill='url(#a_SkrillIcon)'
      d='m50.896 2.783 5.318-.946v20.406h-5.318V2.783Zm7.763 0v19.448h5.319V1.837l-5.319.946ZM43.335 22.231h5.319V7.313h-5.319v14.918ZM8.192 9.42c-.676-.045-2.231-.146-2.231-1.544 0-1.69 2.242-1.69 3.076-1.69 1.476 0 3.391.44 4.755.845 0 0 .766.27 1.408.541l.057.011v-4.62l-.08-.022c-1.61-.563-3.481-1.104-6.963-1.104C2.22 1.837.102 5.33.102 8.316c0 1.724.743 5.78 7.605 6.253.586.034 2.13.124 2.13 1.578 0 1.194-1.262 1.904-3.392 1.904-2.332 0-4.586-.597-5.96-1.16v4.766c2.05.54 4.371.81 7.087.81 5.86 0 8.485-3.3 8.485-6.58 0-3.718-2.93-6.129-7.865-6.467Zm32.146-2.321c-4.913.158-7.403 2.366-7.403 6.75v8.394H38.3v-6.851c0-2.625.338-3.752 3.459-3.854V7.167c-.54-.102-1.42-.068-1.42-.068Zm-14.422.203c-.113.293-.97 2.704-3.031 5.194V1.836L17.397 2.92V22.23h5.488V16.26c1.588 2.39 2.377 5.972 2.377 5.972h6.57c-.654-2.704-3.494-7.684-3.494-7.684 2.547-3.234 3.673-6.682 3.854-7.257h-6.276v.012Zm20.067-1.42a2.626 2.626 0 0 0 2.626-2.614A2.628 2.628 0 0 0 45.983.643a2.618 2.618 0 0 0-2.614 2.625 2.616 2.616 0 0 0 2.614 2.614Z'
    />
    <defs>
      <linearGradient
        id='a_SkrillIcon'
        x1='.113'
        x2='63.981'
        y1='11.548'
        y2='11.548'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#872166' />
        <stop offset='1' stopColor='#4F2455' />
      </linearGradient>
    </defs>
  </svg>
);
